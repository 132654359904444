*{
  box-sizing: border-box;
  margin:0;
  padding:0;
}
.component{
  margin:80px 20px 10px 200px;
}
.card{
  background-color: rgb(232,243,250);
  padding:1.5em;
  box-shadow: 3px 3px 7px 2px rgba(161, 162, 163, 0.856);
  border: none;
  overflow: auto;
}


 /* ********************** Form Style *************************** */
.form-group{
  margin:15px 0px;
}
.form-control{
  padding: 10px ;
  box-shadow: 1px 2px 6px #989898;
}
.form-label{
  font-size: 1.3em;
  font-weight: bold;
}
.select{
  padding:8px;
  margin:10px;
  border-radius: 30px;
  border:none;
  box-shadow: 1px 2px 6px #989898;
}
.select:focus{
  border:none;
  border-radius: 30px;
}
.select:active{
  border:none;
  border-radius: 30px;
}
.error{
  color:red
}
/* #autoComplete{
  background-color: rgb(58,95,133);
} */
.MuiOutlinedInput-root{
  border: none;
}


/* *********************** Button Style ************************** */
.btn{
  max-width: 150px;
  width: 150px;
  border-radius: 45px;
  background-color: rgb(58,95,133);
  color: white;    
  border: none;
  margin:10px 10px 0px 30px;
  box-shadow: 0px 10px 10px #00000029;
  min-height: 35px;
  font-size: revert;
}
.btn:hover{
background-color:rgb(66, 169, 230);
}
.button[disabled=disabled], button:disabled {
  background-color: rgb(66, 169, 230);
  cursor: not-allowed;
}
/* icon button */
.iconButtton{
  background-color:white;
  color: grey;
  font-size: 17px;
  border:none;
  position:relative;
  left:90%;
  bottom:35px;
  font-size: 1.5em;
}
.iconButtton:focus {
  outline: none;
}
.addIcon{
    background-color: #4A88DC;
    padding:12px;
    border-radius: 10px;
    border:none;
}
.addIcon:focus{
  outline: none;
}
/* link */
a{
  color:rgb(49, 50, 51);
}
#link-button{
  color:white;
  position:fixed;
  right:0px;
  top:10px;
}
/* *************************** Table STyle ************************ */

.rowtable{
  background-color: #C3E7FB;
}
th{
  white-space: nowrap;
}

.childBox{
  box-shadow: 3px 3px 7px 2px rgba(161, 162, 163, 0.856);
  border-radius: 5px;
}

/* ************************** Common Style ************************************/

.profile-img {
  display:block;
  margin: auto;
}
/* Dashboard */
.chartCard{    
  background-color: #E8F3FA;
  margin: 0px 5px 20px 5px;
  border: 2px solid #DFDFDF;
  border-radius: 8px;
}

/* User Add */

.box{
  background-color:rgb(232,243,250) ;
  border-radius: 20px;
  border:white solid 8px;
}

.buttonStyle{
  background-color: white;
  border: none;
}

/************************************** login ************************************/
.login-button{
  margin-right: 120%;
   background-color:rgb(41,88,140); 
   color:white;
   width:150px;
   border-radius:12px;
   padding:5px;
   font-size:18px;
}
.login-input{
  padding:1.2em;
  padding-left: 50px;
  width:100%;
  border-radius: 10px; 
  box-shadow:10px 5px 7px rgba(10, 9, 9, 0.376) ;                      
}
.login-title{
  font-weight: bold;
  color: rgb(71,115,160);
}
.login-input::placeholder{
  font-size: 1.2em;
}

@media (max-width:600px){
  .component{
    margin-left: 20px;
    margin-top: 80px;
  }

}

.greenish{
  border:2px solid rgb(8, 252, 8);
  border-left: none;
  border-right: none;
  color:rgb(52, 187, 52);
}
