
  .button {
    background-color:  #2185D0; 
    color: white; 
    border: 2px solid #2185D0;
    width: 150px;
    height: 40px;
    font-weight: bold;
    border-radius: 5px;
  }
  
  .button:hover {
    background-color:white;
    color: black;
    width: 150px;
    height: 40px;
  }

  .button1 {
    background-color:  green; 
    color: white; 
    border: 2px solid green;
    width: 150px;
    height: 40px;
    font-weight: bold;
    border-radius: 5px;
  }
  
  .button1:hover {
    background-color:white;
    color: black;
    width: 150px;
    height: 40px;
  }